import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { StateViewerService } from '../state/state-viewer.service';
import { FormSubmissionService } from '../services/form-submission.service';
import {
  Attachment,
  DefaultDisplayMode,
  Facility,
  GuidedExperienceDTO,
  GuidedExperienceInstanceDTO,
  Image,
  Logo,
  LogoService,
  MM_EVENTNAME,
  MMPayloadFormDataChangeRequest,
  MMPayloadNextFormRequest,
  MMPayloadSignaturePromptAction,
  MMPayloadSignatureSubmitRequest,
  MultiMonitorService,
  SignatureFor,
  SignaturePromptAction,
  SignatureType,
  SubmissionType,
  ViewerIntegrationMode,
  WindowMessageEventName,
} from '@next/shared/common';
import {
  NextAdminService,
  NextFormService,
  NextImageService,
  UserResolverService,
} from '@next/shared/next-services';
import { MainComponent } from '@next/gx-viewer/feature-shell';
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  lastValueFrom,
  merge,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  ConfirmationDialogComponent,
  GenerateComponentDirective,
} from '@next/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  SignaturePromptComponent,
  SignatureProperties,
} from '../signature-prompt/signature-prompt.component';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { StoreService } from '../state/store.service';
import { FormsUtilityService } from '../services/forms-utility.service';

type MMEventTypes = MMPayloadFormDataChangeRequest | MMPayloadSignatureSubmitRequest | MMPayloadNextFormRequest | MMPayloadSignaturePromptAction;


@Component({
  selector: 'next-clinical-form-viewer',
  templateUrl: './clinical-form-viewer.component.html',
  styleUrls: ['./clinical-form-viewer.component.scss']
})
export class ClinicalFormViewerComponent implements AfterViewInit, OnDestroy {
  mmSyncCleanup: Subject<void> = new Subject<void>();

  @ViewChild(GenerateComponentDirective) generateComponent: GenerateComponentDirective;
  @ViewChild('lockedFieldsPrompt', {static: true}) private lockedFieldsPrompt: TemplateRef<any>;

  @Output() pdfPageRendered: EventEmitter<any> = new EventEmitter<any>()
  @Output() pdfPagesLoaded: EventEmitter<any> = new EventEmitter<any>()
  @Output() formSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  routeData: { integration: ViewerIntegrationMode, attachments: Attachment[], experience: GuidedExperienceDTO, isDashboardView: boolean, prefill: unknown, submission: unknown, task: any } = null;
  obsCleanup: Subject<void> = new Subject();
  modalRef: BsModalRef;

  viewerMain: ComponentRef<MainComponent>;
  eventBus: any;
  viewerApp: any;

  loading: boolean = true;
  isDirtyRead: boolean = false;
  userWantsToSkippMissingContent: boolean = false;
  isPatientViewMode: boolean = false;
  emptyLockedFields: string[] = [];
  logo: Logo = { src: '', id: '' };

  constructor (
    public stateViewerSvc: StateViewerService,
    private modalSvc: BsModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private translateSvc: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private adminSvc: NextAdminService,
    private formSubmitSvc: FormSubmissionService,
    private toastSvc: ToastrService,
    private userResolver: UserResolverService,
    private logoService: LogoService,
    private stateSvc: StoreService,
    private nextImageSvc: NextImageService,
    private formSvc: NextFormService,
    private formUtilitySvc: FormsUtilityService,
    private mmSvc: MultiMonitorService
  ) {
    this.stateViewerSvc.shouldAutoSaveRecord = true;
  }

  ngAfterViewInit(): void {
    this.route.data.pipe(
      map((resolveData: { attachments: Attachment[], experience: GuidedExperienceDTO, isDashboardView: boolean, prefill: unknown, submission: unknown, task: any }) => { return { integration: ViewerIntegrationMode.CLINICAL, ...resolveData}; }),
      tap((resolvedData: { integration: ViewerIntegrationMode, attachments: Attachment[], experience: GuidedExperienceDTO, isDashboardView: boolean, prefill: unknown, submission: unknown, task: any }) => this.routeData = resolvedData),
      filter(() => !!this.stateViewerSvc.selectedForms.currentForm),
      tap(async () => {
        await this.initializeViewer().catch((err) => {
          this.toastSvc.error(err.message, '', { disableTimeOut: true })
        });
        // NOTE: mm service routines should only execute when mm mode is enabled
        this.secondMonitorFormSync$().pipe(
          takeUntil(this.obsCleanup)
        ).subscribe();
        this.adminSvc.createMetric('StartFormPDF', this.stateViewerSvc.selectedForms.currentForm);
      }),
      takeUntil(this.obsCleanup),
    ).subscribe();

    this.onDataSetsApplied().pipe(
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  async ngOnDestroy(): Promise<void> {
    this.executeAutoSaveFeature();
    this.eventBus = null;
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  async initializeViewer(): Promise<void> {
    this.loading = true;
    this.stateViewerSvc.dataset = null;
    this.generateComponent.viewContainerRef.clear();
    this.viewerMain = this.generateComponent.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(MainComponent));
    this.viewerMain.instance.integration = this.routeData.integration;
    this.viewerMain.instance.viewMode$ = new BehaviorSubject<string>(this.routeData.experience.metadatadefaultdisplaymode || 'pdf');
    const exInstance: GuidedExperienceInstanceDTO = await this.createClinicalExInstance(this.routeData as GuidedExperienceInstanceDTO);

    this.viewerMain.instance.viewMode$.pipe(
      distinctUntilChanged(),
      // Note: Delay() necessary to ensure GxViewerModule.main.ts [*ngSwitch]
      // directive fires before the setClinicalMode callbacks in this subsequent tap()
      delay(0),
      tap((mode) => {
        if (mode === DefaultDisplayMode.PDF) this.setClinicalModePDF();
        this.stateViewerSvc.viewMode$.next(mode);
        this.viewerMain.instance.instance$.next(exInstance);
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  private setClinicalModePDF(): void {
    this.viewerMain.instance.nxtPdfViewer.facilityLogo = this.logo;
    this.viewerMain.instance.nxtPdfViewer.signQueueSignatures = this.getSignQueueTaskFields(this.routeData);
    this.viewerMain.instance.nxtPdfViewer.isPatientView.next(this.isPatientViewMode);
    this.stateViewerSvc.isPatientView$.pipe(
      filter(() => this.viewerMain.instance.viewMode$.getValue() === 'pdf'),
      tap(result => {
        this.isPatientViewMode = result;
        if (this.viewerMain.instance.nxtPdfViewer.isPatientView.value !== this.isPatientViewMode) {
          this.viewerMain.instance.nxtPdfViewer.isPatientView.next(this.isPatientViewMode);
          this.stateViewerSvc.selectedForms.resetAllFormsToNotProcessed();
        }
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();

    this.viewerMain.instance.nxtPdfViewer.pdfViewerEmitter.pipe(
      filter(() => this.viewerMain.instance.viewMode$.getValue() === 'pdf'),
      tap(event => this.pdfViewerEventCallbacks(event)),
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  private onDataSetsApplied(): Observable<any> {
    return this.stateViewerSvc.dataset$.pipe(
      filter(datasetValue => !!datasetValue),
      filter(() => !!this.viewerMain.instance.instance$ && !!this.viewerMain.instance.instance$.getValue()),
      tap(() => this.isDirtyRead = true),
      tap((dataset) => {
        const instance = this.viewerMain.instance.instance$.getValue();
        instance.prefill = { ...instance.prefill, ...dataset };
        if (this.viewerMain.instance.viewMode$.getValue() === DefaultDisplayMode.PDF) {
          this.viewerMain.instance.nxtPdfViewer.prefill = instance.prefill;
          this.viewerMain.instance.nxtPdfViewer.setFormParameters();
          this.stateViewerSvc.formSavedFromDataSet = true;
        }
        else if (this.viewerMain.instance.viewMode$.getValue() === DefaultDisplayMode.WEB) {
          this.viewerMain.instance.nxtWebViewer.prefill = instance.prefill;
          this.viewerMain.instance.nxtWebViewer.setFormParameters();
        }
      }));
  }

  /**
   * Immediately apply a User's default signature.
   * Since we're not launching the signature prompt, we need to
   * imitate its signature apply event submission payload
   * Note: consider adding a static public function to signature prompt
   * component so that it can be used in both locations.
   * @param properties */
  async autoSign(properties: SignatureProperties): Promise<void> {
    if (properties.signatureFor === 'staff') {
      const user = this.userResolver.user;
      const loggedInUser = { id: user.oid, firstName: user.firstName, lastName: user.lastName, fullName: `${user.firstName} ${user.lastName}`};
      const defaultSignaturesApi$ = this.adminSvc.getPreference(loggedInUser.id, 'DEFAULTSIGNATURES').pipe(
        map(response => response[0].data));
      const defaultSignatures = await lastValueFrom(defaultSignaturesApi$);
      const autoSignatureValue: any = {
        SignedDate: new Date(),
        Name: loggedInUser.fullName,
      };
      switch (properties.signatureType) {
        case SignatureType.DrawnSignature:
        case SignatureType.Signature:
          autoSignatureValue.Strokes = defaultSignatures.STROKES;
          break;
        case SignatureType.TypedSignature:
          autoSignatureValue.Text = defaultSignatures.TEXT;
          break;
        case SignatureType.Initials:
          autoSignatureValue.Initials = defaultSignatures.INITIALS;
          break;
      }
      this.viewerMain.instance.nxtPdfViewer.onSelfSign({
        id: this.userResolver.user.oid,
        signatureType: properties.signatureType,
        Name: properties.signatureName,
        CaptureSignerName: properties.captureSignerName,
        CaptureRelationship: properties.captureRelationship,
        Value: autoSignatureValue,
        applySignature: false
      });
    }
  }

  private processSignature(properties: SignatureProperties): void {
    const viewerFormData = this.viewerMain.instance.nxtPdfViewer.formData || {};
    this.emptyLockedFields = properties?.lockedFieldNames?.filter(f => {
      const dataField = viewerFormData[f];
      return !dataField?.Value?.Text && this.viewerMain.instance.nxtPdfViewer.isRequiredField(f);
    });
    if (this.emptyLockedFields?.length) {
      this.showLockedFieldsPrompt();
    }
    else {
      this.signaturePrompt(properties);
      if (properties.signatureFor === SignatureFor.Patient) {
        // TODO: only run mm svc routines when mm is active
        this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({ type: SignaturePromptAction.OPEN, data: properties });
      }
    }
  }

  private showLockedFieldsPrompt(): void {
    this.modalRef = this.modalSvc.show(this.lockedFieldsPrompt, {
      class: 'modal-confirmation',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }

  exitLockedFieldsModal(): void {
    this.modalRef.hide();
    this.viewerMain.instance.nxtPdfViewer.focusOnField(this.emptyLockedFields[0]);
  }

  /**
   * Open the signature prompt modal
   * @param properties - SignatureProperties */
  signaturePrompt(properties: SignatureProperties): void {
    const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
    const fullName: string = `${this.stateViewerSvc.selectedForms.currentForm.patientdata.firstname} ${this.stateViewerSvc.selectedForms.currentForm.patientdata.lastname}`;
    const signaturePromptModal = this.modalSvc.show(SignaturePromptComponent, {
      class: 'modal-iop-signature-prompt',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        form: this.stateViewerSvc.selectedForms.currentForm,
        taskToSign: this.stateViewerSvc.signQueue.tasksToSign[0] ?? null,
        batchSign: this.routeData.isDashboardView,
        signatureProperties: properties,
        patientName: this.toTitleCase(fullName),
        isPatientView: this.isPatientViewMode,
        useTopaz: this.stateViewerSvc.useTopazDevice
      }
    });

    signaturePromptModal.content.signature.subscribe(val => {
      this.staffSignature(val);
    });

    signaturePromptModal.content.selfSignSignature.subscribe(val => {
      this.selfSignSignature(val);
    });

    signaturePromptModal.content.exitModal.subscribe(() => {
      signaturePromptModal.hide();
    });

    signaturePromptModal.content.closeReassignModal.subscribe((assignee) => {
      signaturePromptModal.hide();
      const updateSig = () => {
        this.eventBus.dispatch('reassign-signature');
      };
      viewerPDFComponent.getFormsSignatures(updateSig).then(() => {
        this.toastSvc.success(this.translateSvc.instant('SIGNATURE_PROMPT.REASSIGN_SUCCESS', { assignee: assignee }));
      });
    });
  }

  onPdfPagesLoaded(): void {
    this.pdfPagesLoaded.emit();
  }

  onPdfPageRendered(viewerApp: any): void {
    this.loading = true;
    this.viewerApp = viewerApp;
    this.eventBus = viewerApp.eventBus;
    this.loading = false;

    this.pdfPageRendered.emit();
  }

  missingContentInFormsPrompt(): void {
    const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, {
      initialState: {
        title: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT_IN_ALL_FORMS.TITLE'),
        message: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT_IN_ALL_FORMS.CONTENT'),
        cancelButton: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT_IN_ALL_FORMS.NO'),
        confirmButton: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT_IN_ALL_FORMS.YES')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    });
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        viewerPDFComponent.submit(SubmissionType.Submitted, true, false).then(() => {
          this.navigateFromCurrentForm();
        }).finally(() => this.loading = false);
      } else {
        this.loading = false;
      }
    }, (err) => { this.toastSvc.error(err.message, '', {disableTimeOut: true}) });
  }

  missingContentPrompt(): void {
    const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, {
      initialState: {
        title: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT.TITLE'),
        message: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT.CONTENT'),
        cancelButton: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT.NO'),
        confirmButton: this.translateSvc.get('FORM_VIEWER.MISSING_CONTENT.YES')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    });
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        viewerPDFComponent.submit(SubmissionType.Saved, true, false);
      } else {
        this.loading = false;
      }
    }, (err) => { this.toastSvc.error(err.message, '', {disableTimeOut: true}) });
  }

  navigateFromCurrentForm(): void {
    this.stateViewerSvc.selectedForms.SetCurrentFormToProcessed();
    if (this.router.url.startsWith("/dashboard/view")) {
      this.stateViewerSvc.signQueue.unselect();
      this.router.navigate(['/dashboard/view'],{ queryParams: { form: false } });
    }
    else if (this.stateViewerSvc.selectedForms.isLastForm) {
      this.stateViewerSvc.moveToNextForm.next(null);
      this.mmSvc.sync.sendSelectedFormsComplete();
    }
  }

  pdfViewerEventCallbacks(event: any): void {
    try {
      this.checkForSignatures();
      switch (event.eventName) {
        case WindowMessageEventName.SubmitError: {
          this.formSubmitSvc.updateErrorPrompt(this.stateViewerSvc.selectedForms.currentForm.id);
          break;
        }
        case WindowMessageEventName.PagesLoaded: {
          this.onPdfPagesLoaded();
          break;
        }
        case WindowMessageEventName.PageRendered: {
          this.onPdfPageRendered(event.viewerApp);
          break;
        }
        case WindowMessageEventName.ExperienceSave:
        case WindowMessageEventName.ExperienceSubmit: {
          this.isDirtyRead = false;
          this.navigateFromCurrentForm();
          lastValueFrom(this.formSvc.getForm(event.formId)).then(response => {
            this.stateViewerSvc.selectedForms.update(response);
          });
          break;
        }
        case WindowMessageEventName.Signature: {
          if (this.routeData.isDashboardView) {
            this.autoSign(event.signatureProperties);
          }
          else {
            this.processSignature(event.signatureProperties);
          }
          break;
        }
        case WindowMessageEventName.FieldChanged: {
          this.isDirtyRead = true;
          delete event.eventName;
          event.currentForm = this.stateViewerSvc.selectedForms.currentForm;
          event.submission = {
            submissiontype: SubmissionType.Saved
          };
          this.stateViewerSvc.renderedForm = event;
          //TODO: only execute mm routines while mm is active
          this.mmSvc.sync.sendRequestFieldChange.toRemote({
            fieldValue: event.formData[event.fieldConfig.name],
            fieldConfig: event.fieldConfig,
            formData: event.formData
          });
          break;
        }
        default:
          break;
      }
    } finally {
      this.loading = false;
    }
  }

  nextFormWeb(): void {
    this.loading = true;
    this.isDirtyRead = false;
    setTimeout(() => {
      const viewerWEBComponent = this.viewerMain.instance.nxtWebViewer;
      viewerWEBComponent.submit(SubmissionType.Submitted, '', true).then(() => {
        this.navigateFromCurrentForm();
      }).finally(() => this.loading = false);
    });
  }

  nextForm(): void {
    this.loading = true;
    // Since we are timing out here we need to set the dirty read to false right away so that the next form can be submitted
    // and the execute auto save feature
    this.isDirtyRead = false;
    // Wrap logic in timeout to allow field blur event to be registered (to update conditionally required fields)
    setTimeout(() => {
        const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
        if (this.stateViewerSvc.signQueue.selectedTaskToSign) {
          this.stateViewerSvc.signQueue.selectedTaskToSign.isBypassed = !viewerPDFComponent.isFormValidForCurrentView;
          this.stateViewerSvc.signQueue.selectedTaskToSign.isCompleted.next(viewerPDFComponent.isFormValidForCurrentView);
        }
        if (this.isPatientViewMode && this.stateViewerSvc.selectedForms.isLastFormWithOtherDirtyForms) {
          this.missingContentInFormsPrompt();
        }
        else if (!viewerPDFComponent.isFormValidForCurrentView) {
          this.missingContentPrompt();
        }
        else {
          viewerPDFComponent.submit(SubmissionType.Submitted, true, false);
        }
    }, 250);
  }

  staffSignature(event): void {
    this.viewerMain.instance.nxtPdfViewer.onSignature(event);
  }

  selfSignSignature(event): void {
    this.viewerMain.instance.nxtPdfViewer.onSelfSign(event);
  }

  checkForSignatures(): boolean {
    const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
    const signatureCount: number = viewerPDFComponent.formData
      ? Object.values(viewerPDFComponent.formData).filter((value: any) => (value && (value.Type === 'TypedSignature' || value.Type === 'Signature')) && value.Value.SignedDate).length
      : 0;
    this.stateViewerSvc.allSignature = signatureCount;
    return !!signatureCount;
  }

  clearSignatures() {
    if (this.checkForSignatures()) {
      const viewerPDFComponent = this.viewerMain.instance.nxtPdfViewer;
      const config  = {
        initialState: {
          title: this.translateSvc.get('CONFIRMATION_DEL.TITLE'),
          message: this.translateSvc.get('CONFIRMATION_DEL.TASK'),
          cancelButton: this.translateSvc.get('CONFIRMATION_DEL.CANCEL'),
          confirmButton: this.translateSvc.get('CONFIRMATION_DEL.CONFIRM')
        },
        class: 'modal-confirmation',
        ignoreBackdropClick: true,
        keyboard: false
      };
      this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
      this.modalRef.content.onClose.subscribe(result => {
        if (result) {
          this.eventBus.dispatch('clear-signatures');
          viewerPDFComponent.submit(SubmissionType.Saved);
        }
      }, (err) => { this.toastSvc.error(err.message, '', {disableTimeOut: true}) });
    }
  }

  missingContentModalResult(result: boolean) {
    this.userWantsToSkippMissingContent = result;
    this.modalRef.hide();
  }

  async executeAutoSaveFeature() {
    if (this.stateViewerSvc.shouldAutoSaveRecord && this.isDirtyRead) {
      await this.formSubmitSvc.saveForm(false);
    }
  }

  /**
   * If the route contains tasks we are rendering from sign queue
   * So retrieve the fields listed in the task
   * @param data
   */
  getSignQueueTaskFields(data: { integration: ViewerIntegrationMode, attachments: Attachment[], experience: GuidedExperienceDTO, isDashboardView: boolean, prefill: unknown, submission: unknown, task: any }): string[] {
    const fieldNames: string[] = [];
    if (data.isDashboardView && data.task?.fieldids) {
      fieldNames.push(...data.task.fieldids.split(','));
    }
    return fieldNames.length ? fieldNames : null;
  }

  private toTitleCase(str): string {
    return str[0].toUpperCase() + str.slice(1);
  }

  private async createClinicalExInstance(experienceInstance: GuidedExperienceInstanceDTO): Promise<GuidedExperienceInstanceDTO> {
    const facilityReference: Facility = this.formUtilitySvc.getFacilityReference(this.stateViewerSvc.selectedForms.currentForm);
    if (facilityReference?.logoid) {
      const image: Image = await this.nextImageSvc.getImageById(facilityReference.logoid).toPromise()
      if (image) {
        this.logo = {
          id: facilityReference.logoid,
          src: await this.logoService.getBase64Logo(image.secureUrl)
        };
      }
    }


    if (this.routeData.isDashboardView) {
      // If we are in dashboard view, take prefill from the existing submission only
      experienceInstance.prefill = (this.routeData?.submission as any)?.data?._prefill_c4af0d49_e948_4737_8c12_8d64511faeec ?? { };
    }
    else {
      experienceInstance.prefill.Template = {
        ...experienceInstance.prefill.Template,
        metadatadepartments: experienceInstance.experience?.metadatadepartments?.map(f => this.stateSvc.departments.find(x => x.id === f)?.name).join(', '),
        metadatafacilities: experienceInstance.experience?.metadatafacilities?.map(f => this.stateSvc.facilitiesByUserGroup.find(x => x.id === f)?.name).join(', ')
      }
      experienceInstance.prefill.Facility = {
        ...experienceInstance.prefill.Facility,
        ...facilityReference
      };
      experienceInstance.prefill.Department = {
        ...experienceInstance.prefill.Department,
        name: experienceInstance.prefill.Appointment?.departmentname,
        shortname: experienceInstance.prefill.Appointment?.departmentshortname,
        departmentid: experienceInstance.prefill.Appointment?.department,
      }
    }
    return experienceInstance;
  }

  secondMonitorFormSync$(): Observable<MMEventTypes> {
    this.mmSyncCleanup.next();  // End previous subscription

    return this.mmSvc.sync.receiveCurrentFormRequest$().pipe(
      takeUntil(this.mmSyncCleanup),
      switchMap((): Observable<GuidedExperienceInstanceDTO> => {
        return this.viewerMain.instance.instance$.pipe(
          filter((instance: GuidedExperienceInstanceDTO) => !!instance))
      }),
      tap((instance: GuidedExperienceInstanceDTO): void => {
        const id: string = this.stateViewerSvc.selectedForms.currentForm.id;
        const view: string = this.stateViewerSvc.viewMode$.getValue();
        let logo: Logo = null;
        let formData: any = null;
        switch (this.viewerMain.instance.viewMode$.getValue()) {
          case DefaultDisplayMode.WEB:
            formData = this.viewerMain.instance.nxtWebViewer.formData;
            break;
          case DefaultDisplayMode.PDF:
            logo = this.viewerMain.instance.nxtPdfViewer.facilityLogo;
            formData = this.viewerMain.instance.nxtPdfViewer.formData;
            break;
        }
        this.mmSvc.sync.sendRequestedCurrentForm(id, view, instance, formData, this.stateViewerSvc.useTopazDevice, logo);
      }),
      switchMap((): Observable<any> => {
        const fieldChange$: Observable<MMPayloadFormDataChangeRequest> = this.mmSvc.sync.receiveRequestFieldChange$.fromRemote();
        const signaturePromptAction$: Observable<MMPayloadSignaturePromptAction> = this.mmSvc.sync.receiveRequestSignaturePromptAction$().pipe(
          filter(() => !this.stateViewerSvc.useTopazDevice)
        );
        const nextForm$: Observable<any> = this.mmSvc.sync.receiveRequestNextForm$();
        return merge(fieldChange$, nextForm$, signaturePromptAction$).pipe(
          takeUntil(this.mmSyncCleanup));
      }),
      map((message: MMPayloadFormDataChangeRequest | MMPayloadSignatureSubmitRequest | MMPayloadNextFormRequest | MMPayloadSignaturePromptAction) => message),
      tap((message: MMPayloadFormDataChangeRequest | MMPayloadSignatureSubmitRequest | MMPayloadNextFormRequest | MMPayloadSignaturePromptAction): void => {
        switch (message.eventName) {
          case MM_EVENTNAME.FORM_NEXT_REQUEST: {
            if (this.stateViewerSvc.viewMode$.getValue() === DefaultDisplayMode.PDF) {
              this.nextForm();
            }
            else if (this.stateViewerSvc.viewMode$.getValue() === DefaultDisplayMode.WEB) {
              this.nextFormWeb();
            }
            break;
          }
          case MM_EVENTNAME.DATA_CHANGE_REQUEST: {
            const fieldValue = message.data.fieldValue;
            const fieldConfig = message.data.fieldConfig;
            const updatedField = { [fieldConfig.name]: fieldValue };
            const formData = this.viewerMain.instance.nxtPdfViewer.formData;

            if (JSON.stringify(formData[fieldConfig.name]) !== JSON.stringify(fieldValue)) {
              this.viewerMain.instance.nxtPdfViewer.updateFormFields(updatedField, formData);
            }
            break;
          }
          case MM_EVENTNAME.SIG_PROMPT_ACTION: {
            if (message.type === SignaturePromptAction.OPEN) {
              this.signaturePrompt(message.data);
            } else if (message.type === SignaturePromptAction.APPLY) {
              // Currently capturing this event in sig-prompt
            }
            break;
          }
        }
      }));
  }
}
